import { useCurrencyStore } from '~/stores/currency';

export function useApiWorldwide() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getWorldwideData = async (propertyType) => {
    let result = ref(null);

    const callKey = `getWorldwideData${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = `worldwide/${propertyType}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  return { getWorldwideData };
}
