<script setup>
import { findPropertyTypeByKey } from '~/utils/propertyTypes';
import { DEFAULT_CURRENCY } from '~/config/currencies.js';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
  propertyType: {
    type: String,
    required: true,
  },
  propertyList: {
    type: Array,
    required: true,
  },
});

const { t } = useI18n();
const { useUrl } = useUrls();
const { useImage } = useImages();

const topPropertiesList = computed(() => {
  if (!props.propertyList?.length) {
    return {};
  }
  return props?.propertyList?.map((property) => {
    const propertyImage = property?.image?.small || null;
    const propertyImageUrl = propertyImage ? useImage.getImage(propertyImage) : null;

    const actualPropertyTypeObj = findPropertyTypeByKey(property?.type);
    const locationData = {
      propertyTypeSlug: t(actualPropertyTypeObj.slug),
      propertyId: property.id,
      urlFriendlyProperty: property.urlFriendlyName,
    };

    const propSharedMinPrice = property?.sharedMinPrice?.value || 0;
    const propPrivateMinPrice = property?.privateMinPrice?.value || 0;
    const propPriceCurrency
      = property?.sharedMinPrice?.currency || property?.privateMinPrice?.currency || DEFAULT_CURRENCY.code;
    const propPrices = [Number.parseFloat(propSharedMinPrice), Number.parseFloat(propPrivateMinPrice)].filter(
      (price) => price > 0,
    );

    return {
      id: property.id,
      name: property.name,
      image: propertyImageUrl,
      location: property?.city,
      rating: {
        score: property?.avgRating,
        showReviews: false,
        showKeyword: false,
      },
      price: {
        value: Math.min(...propPrices),
        currency: propPriceCurrency,
      },
      link: useUrl.getPropertyPageUrl(locationData),
    };
  });
});
</script>

<template>
  <component
    :is="tag"
    class="top-properties-section"
  >
    <h3 v-html="$t('t_RWDTOPPROPERTYTYPES', { PROPERTYTYPE: `<span>${propertyType}</span>` })" />
    <div class="top-properties-list">
      <CommonHwDestinationCard
        v-for="property in topPropertiesList"
        :key="property.id"
        v-bind="property"
      />
    </div>
  </component>
</template>

<style scoped lang="scss">
.top-properties-section {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-l;

  h3 {
    @include title-2-bld;

    color: $wds-color-ink-darker;

    :deep(span) {
      color: $wds-color-purple;
    }
  }

  .top-properties-list {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
  }

  @include tablet {
    h3 {
      @include title-1-bld;
    }
  }

  @include desktop {
    gap: wds-rem(56px);
    align-items: center;

    h3 {
      @include lg-title-3-bld;
    }

    .top-properties-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: $wds-spacing-l;
      width: 100%;
    }
  }
}
</style>
