<script setup>
import { WdsUgcCard } from '@wds/components';
import { DEFAULT_CURRENCY } from '~/config/currencies.js';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
  propertyTypeObj: {
    type: Object,
    required: true,
  },
  cityList: {
    type: Array,
    required: true,
  },
});

const propertyTypeObj = toRef(props, 'propertyTypeObj');

const { t } = useI18n();
const { useUrl } = useUrls();
const { useFormatter } = useFormatters();
const { useImage } = useImages();

function destinationName(city) {
  return t('t_A11YBCTEXTLOCATION', {
    LOCATIONNAME: city.name,
    PROPERTYTYPE: t(propertyTypeObj.value.transcode),
  });
}

function destinationPrice(city) {
  const priceValue = city?.pricesFrom?.value || 0;
  const priceCurrency = city?.pricesFrom?.currency || DEFAULT_CURRENCY.code;
  const priceString = priceValue > 0 ? useFormatter.formatCurrency(priceValue, priceCurrency) : null;
  return priceString ? `${t('t_PRICESFROM').toLowerCase()} ${priceString}` : '';
}

function destinationLink(city) {
  const locationData = {
    propertyTypeSlug: propertyTypeObj.value.slug,
    urlFriendlyContinent: city.urlFriendlyContinent,
    urlFriendlyCountry: city.urlFriendlyCountry,
    urlFriendlyCity: city.urlFriendlyName,
  };
  return useUrl.getCityPageUrl(locationData);
}

function destinationImage(imageUrl) {
  return imageUrl ? useImage.getOptimizedImageUrl(imageUrl, { height: 370 }) : useImage.getPlaceholderImage();
}
</script>

<template>
  <component
    :is="tag"
    class="top-cities-section"
  >
    <div class="top-cities-heading">
      <h2 v-html="$t('t_TOPCITIESWORLDWIDE')" />
      <span>{{ $t('t_DISCOVERPLACESFORADVENTURES') }}</span>
    </div>
    <div class="top-cities-list">
      <WdsUgcCard
        v-for="city in cityList"
        :key="city.id"
        :image="destinationImage(city.image.heroImage)"
        :social-handle="city.image.ugcHandle"
        :main-heading="destinationName(city)"
        :secondary-heading="destinationPrice(city)"
        :link="destinationLink(city)"
        class="top-city-card"
        loading="lazy"
      />
    </div>
  </component>
</template>

<style scoped lang="scss">
.top-cities-section {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-xl;

  .top-cities-heading {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-s;
    align-items: flex-start;

    @include title-6-reg;

    h2 {
      @include title-2-bld;

      color: $wds-color-ink-darker;

      :deep(span) {
        color: $wds-color-purple;
      }
    }
  }

  .top-cities-list {
    display: inline-flex;
    gap: $wds-spacing-m;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @include tablet {
    .top-cities-heading {
      h2 {
        @include title-1-bld;
      }
    }
  }

  @include desktop {
    gap: $wds-spacing-xxl;
    align-items: center;

    .top-cities-heading {
      align-items: center;

      h2 {
        @include lg-title-3-bld;
      }
    }

    .top-cities-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: $wds-spacing-xl;
      width: 100%;
      overflow: visible;

      .top-city-card {
        &:nth-child(2n) {
          margin-top: wds-rem(88px);
        }
      }
    }
  }
}
</style>
